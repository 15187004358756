import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Only for requests to same domain and token exists
        if (request.url.startsWith('/') && window.sessionStorage.getItem('token')) {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${window.sessionStorage.getItem('token')}`),
            });
        }

        return next.handle(request);
    }
}
