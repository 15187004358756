import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { fromEvent, Observable, retry, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NoConnectionInterceptor implements HttpInterceptor {
    private onlineChanges$ = fromEvent(window, 'online').pipe(map(() => !!navigator.onLine));

    public get isOnline(): boolean {
        return navigator.onLine;
    }

    public constructor(private snackBar: MatSnackBar) {
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            retry({
                delay: (errors: Observable<unknown>) => {
                    // When we are online passTrough the error and do not retry
                    if (this.isOnline) {
                        return errors.pipe(switchMap(err => throwError(() => err)));
                    }

                    // Change what happens on no connection.
                    const toast = this.snackBar.open('Your currently offline, check your internet connection!');

                    return this.onlineChanges$.pipe(tap((online: boolean) => {
                        if (online) {
                            toast.dismiss();
                        }
                    }));
                }
            })
        );
    }
}
